'use strict';

import React from 'react';
import AOS from 'aos';
import {capitalize} from 'lodash';

import translate from "../translate";
import paths from '../../lib/paths'
import SectionDiscoverEcosystem from '../section-discover-ecosystem';

class Products extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });
    }

    openMobDescription(id) {
        const description = document.getElementById(id);
        if(description.classList.contains('active')) {
            description.classList.remove('active');
        } else {
            description.classList.add('active');
        }
    }

    redirect(path, side) {
        const width = window.innerWidth;

        if(width >= 1365) {
            const element = document.getElementById('product-section');

            element.classList.add('active')
            element.classList.add(side)

            setTimeout(() => this.props.history.push(path), 2500)
        } else {
            this.props.history.push(path)
        }

    }


    render() {
        const {t} = this.props;
        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {
                                    t('Möchten Sie den Energieverbrauch und die $Emissionen $reduzieren? Oder möchten Sie die $Luftqualität in $Innenräumen überwachen?', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className='product-section'>
                        <div id='product-section' className='product-section__row'>
                            <div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="left" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.openMobDescription('left')}>
                                            {t('Heizungsoptimierung')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Mit unserer innovative Swiss Made Technologie den Energieverbrauch und damit die CO2 Emissionen von Gebäuden reduzieren und so die Umwelt clever verbessern`)}
                                        </div>
                                        <a
                                            onClick={() => this.props.history.push(paths.heating)}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="right" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.props.history.push(paths.heating)}>
                                            {t('Luftqualität')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Mit unserer innovativen Plug-and-Play-Lösung garantieren wir eine bessere Raumluftqualität. Dank einer LED-Beleuchtung, die in den Sensor integriert ist, ist es einfach, das Messresultat abzulesen und den Raum entsprechend zu lüften`)}
                                        </div>
                                        <a
                                            onClick={() => this.redirect(paths.air, 'right')}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <SectionDiscoverEcosystem/>
            </>
        )
    }
}

export default translate(Products);
