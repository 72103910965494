'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import buildingIconFirst from '../../assets/icon/discover-application/building-icon-1.svg';
import buildingIconSecond from '../../assets/icon/discover-application/building-icon-2.svg';
import buildingIconThird from '../../assets/icon/discover-application/building-icon-3.svg';
import buildingIconFourth from '../../assets/icon/discover-application/building-icon-4.svg';
import arrowIcon from '../../assets/icon/arrow-pdf.svg';

class SectionDiscoverApplication extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-discover-application-container">
                <div className="section-discover-application-wrapper">
                    <div className="section-discover-application">
                        <div className="section-discover-application-item">
                            <h2 className="section-discover-application-item-title">
                                {t(`component-7-label-1`)}
                            </h2>
                            <div className="section-discover-application-item-description">
                                {t(`component-7-label-2`)}
                            </div>
                            <a href={paths.links.applicationAreas} className="section-discover-application-item-button">
                                {t('component-7-label-3')} <img src={arrowIcon} />
                            </a>
                        </div>
                        <div className="section-discover-application-icons">
                            <img src={buildingIconFirst} />
                            <img src={buildingIconSecond} />
                            <img src={buildingIconThird} />
                            <img src={buildingIconFourth} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionDiscoverApplication);