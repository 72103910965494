'use strict'
import React from 'react';
import $ from 'jquery';
import AOS from 'aos';

import paths from '../../lib/paths';
import translate from "../translate";
import SectionHowMuchImpact from "../section-how-much-impact";

import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import person from '../../assets/icon/product/person.svg';
import weather from '../../assets/icon/product/weather.svg';
import calendar from '../../assets/icon/product/calendar.svg';
import schema from '../../assets/icon/product/schema.svg';
import SectionDiscoverApplication from '../section-discover-application';
import SectionPricingInformation from '../section-pricing-information';
import SectionBookDemo from "../section-book-meeting";
import SectionDemo from "../section-demo";
import BookDemoModal from "../book-demo-modal";

class PageFullSystem extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            openDemoModal: false
        }
    }

    componentDidMount() {
        let getParams = Object.fromEntries(new URLSearchParams(window.location.search));

        if(getParams.demoModalOpen === 'true'){
            this.setState({openDemoModal: true});
        }

        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;
        let videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_DE.mp4';
        if(language === 'fr'){
            videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_FR.mp4'
        }

        return (
            <main className='main'>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('page-2-label-1', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"     style={{height: 700}}  loading={'lazy'} src="https://ik.imagekit.io/sh/FullSystem_04_4ueR64DYSj5K.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit"   style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/FullSystem_04_4ueR64DYSj5K.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                      loading={'lazy'} src="https://ik.imagekit.io/sh/FullSystem_04_4ueR64DYSj5K.jpg?tr=w-700" alt="" />
                    </div>
                    <div className="big-img__text-block">
                        <h3 data-aos="fade-right" data-aos-delay="650" >{t('page-2-label-2')}</h3>
                    </div>

                </section>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row text-section__row--col-3">
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="50">
                                    <h4>{t('page-2-label-3')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-4`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="150">
                                    <h4>{t('page-2-label-5')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-6`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="300">
                                    <h4>{t('page-2-label-7')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-8`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('page-2-label-9', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section id='components' className="components" data-aos-id="fixed" data-aos="fixed" data-aos-offset="">
                    <div className="components__del" data-aos="del-fixed" data-aos-anchor="#del-fixed" data-aos-anchor-placement="top-bottom">
                        <div className="components__item active">
                            <div className="components__info-block">
                                <div className="text-block">
                                    <div className="container">
                                        <h3>
                                            CLEVER
                                            <br />
                                            Thermo.
                                        </h3>
                                        <div className="text-block__info">
                                            {t(`page-2-label-10`)}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('page-2-label-11')}
                                            </li>
                                            <li>
                                                {t('page-2-label-12')}
                                            </li>
                                            <li>
                                                {t('page-2-label-14')}
                                            </li>
                                        </ul>
                                        <a href={paths.links.thermoDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('page-2-label-39')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="components__img-block">
                                <img className="components__img" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-1-2x_XOQHqlyWHee.jpg?tr=w-800"
                                     loading={'lazy'} alt="" />
                            </div>
                        </div>
                        <div className="components__item" data-aos-id="active2" data-aos="active" data-aos-offset="">
                            <div className="components__info-block">
                                <div className="text-block">
                                    <div className="container">
                                        <h3>
                                            CLEVER
                                            <br />
                                            Sense.
                                        </h3>
                                        <div className="text-block__info">
                                            {t(`page-2-label-16`)}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t(`page-2-label-17`)}
                                            </li>
                                            <li>
                                                {t('page-2-label-18')}
                                            </li>
                                        </ul>
                                        <a href={paths.links.senseDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('page-2-label-39')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="components__img-block">
                                <img className="components__img" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-2-2x_DGQmIegQJ.jpg?tr=w-800"
                                     loading={'lazy'} alt="" />
                            </div>
                        </div>
                        <div className="components__item" data-aos-id="active3" data-aos="active" data-aos-offset="">
                            <div className="components__info-block">
                                <div className="text-block">
                                    <div className="container">
                                        <h3>
                                            {t('page-2-label-20')}
                                        </h3>
                                        <div className="text-block__info">
                                            <p>
                                                {t(`page-2-label-21`)}
                                            </p>
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('page-2-label-23')}
                                            </li>
                                            <li>
                                                {t(`page-2-label-25`)}
                                            </li>
                                            <li>{t('page-2-label-27')}</li>
                                        </ul>
                                        <a onClick={() => this.setState({openDemoModal: true})}  className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('page-2-label-44')}
                                        </a>
                                        <BookDemoModal opened={this.state.openDemoModal} onClose={() => this.setState({openDemoModal: false})} />
                                    </div>
                                </div>
                            </div>
                            <div className="components__img-block">
                                <img className="components__img" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-3-2x_OUCU5NFM9.jpg?tr=w-800"
                                     loading={'lazy'} alt="" />
                            </div>
                        </div>
                    </div>

                </section>

                <SectionDemo/>
                <SectionPricingInformation />

                <div className="wrapper" id="del-fixed" data-aos-offset="0">
                    <h3 className="no-margin">
                        {
                            t('page-2-label-28')
                        }
                    </h3>
                </div>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={person} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-29')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-30`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={weather} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-31')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-32`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={calendar} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-33')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-34`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={graphic} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-35')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-36`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={phone} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-37')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-38`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={schema} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-2-label-40')}</h4>
                                    <div className="text-section__info">
                                        {t(`page-2-label-41`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="video-section">
                    <div className="video-section__row">
                        <div className="video-section__text">
                            <div className="video-section__info">
                                {t(`page-2-label-42`)}
                            </div>
                            <a href="#" className="btn">{t('page-2-label-43')}</a>
                        </div>
                        <div className="video-section__video">
                            <div className="video">
                                <video controls style={{
                                    width: '100%'
                                }}
                                       poster={'https://ik.imagekit.io/sh/cleveron_ch/Adliswil_Video_SZPkCn6Zs.PNG?tr=ar-5-3,w-1400'}
                                >
                                    <source
                                        src={'https://cleveron-assets.s3.amazonaws.com/media/_adliswill_CLEVERON.mp4'}
                                        type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionDiscoverApplication/>
                <SectionHowMuchImpact/>
            </main>
        )
    }
}

export default translate(PageFullSystem);
