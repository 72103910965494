'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import arrowIcon from '../../assets/icon/arrow-white.svg';

class SectionDiscoverEcosystem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-discover-ecosystem-container">
                <div className="section-discover-ecosystem-wrapper">
                    <div className="section-discover-ecosystem">
                        <div className="section-discover-ecosystem-item">
                            <h2 className="section-discover-ecosystem-item-title">
                                {t(`component-8-label-1`)}
                            </h2>
                            <div className="section-discover-ecosystem-item-description">
                                {t(`component-8-label-2`)}
                            </div>
                            <a href={'https://info.cleveron.ch/ecosystem_de'} className="section-discover-ecosystem-item-button">
                                {t('component-8-label-3')} <img src={arrowIcon} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionDiscoverEcosystem);