let cnf = {};

cnf.i18n = {
    locales:['de', 'it', 'fr', 'en'],
    preload: ['de', 'it', 'fr', 'en'],
    whitelist: ['de', 'it', 'fr', 'en'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'de',
    saveMissing: true,
    nsSeparator: ':::',
    keySeparator: '::',
    locizeLastUsed: {
        // the id of your locize project
        projectId: 'a9cb2c3d-1e6b-48cd-bc76-7e138bbc09c7',

        // add an api key if you want to send missing keys
        apiKey: 'f3520e54-ec68-4b14-a61e-be3deff1a32a',

        // the reference language of your project
        referenceLng: 'de',

        // debounce interval to send data in milliseconds
        debounceSubmit: 1000,

        // hostnames that are allowed to send last used data
        // please keep those to your local system, staging, test servers (not production)
        allowedHosts: ['cleveron.ch.localhost', 'websitestage.simplyhome.tech']
    },
    detection: {
        order: ['cookie'],
        lookupCookie: 'i18next',
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false
    },
    backend: {
        projectId: 'a9cb2c3d-1e6b-48cd-bc76-7e138bbc09c7',
        apiKey: 'f3520e54-ec68-4b14-a61e-be3deff1a32a',
        referenceLng: 'de',
        allowedAddOrUpdateHosts: (hostname) => {
            if(hostname.includes('cleveron.ch')) return true;
            if(hostname.includes('websitestage.simplyhome.tech')) return true;

            return false;
        }
    }
};

export default cnf;