'use strict';

import React from 'react';

import translate from '../translate';
import PropTypes from "prop-types";
import closeIcon from '../../assets/icon/close.svg';
import Parse from 'parse';
import swal from 'sweetalert';

const isValidEmail = require('is-valid-email');

class BookDemoModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            form: {
                name: '',
                email: '',
                lastname: '',
                position: '',
                company: '',
            },
            errors: {
                name: false,
                email: false,
                company: false
            }
        }
        this.sendForm = this.sendForm.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    inputValues(event, field) {
        let value = event.target.value;

        this.setState(prev => {
            prev.form[field] = value;

            prev.errors[field] = false;

            return prev;
        });
    }

    clearForm() {
        this.setState({
            form: {
                name: '',
                company: '',
                email: '',
                comment: ''
            },
            errors: {
                name: false,
                email: false,
                company: false
            }
        });
    }

    async sendForm(e) {
        const {form: {email, company, lastname, name, position}} = this.state;
        const {t, onClose} = this.props;
        let language = this.props.i18n.resolvedLanguage;

        this.setState({
            form: {...this.state.form},
            errors: {
                name: !name,
                lastname: !lastname,
                email: !email || !isValidEmail(email),
                company: !company,
                isValidEmail: !isValidEmail(email)
            }
        });

        if (!(name && email && company && isValidEmail(email))) {
            e.preventDefault();
            return
        }

        try {
            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            //Chiama webhoock con questi dati.
            await Parse.Cloud.run('request-demo-account', {
                email,
                firstName: name,
                lastName: lastname,
                company,
                position,
                language,
                token
            });

            this.clearForm();

            swal(t('component-12-label-13'), t('component-12-label-12'), 'success').then(function() {
                onClose();
            });
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    render() {
        const {errors, form: {name, email, company, lastname, position}} = this.state;
        const {t, opened} = this.props;
        return (
            <div className={`book-call-modal ${opened ? 'active' : ''}`}>
                <div className="book-call-modal-content">
                    <div className="book-call-modal-header">
                        <span className="close" onClick={() => {
                            this.clearForm();
                            this.props.onClose();
                            this.props.onClose();
                        }}>
                            <img src={closeIcon}/>
                        </span>
                    </div>
                    <div className="book-call-modal-body">
                        <section className="basic-section">
                            <div className="basic-section__row">
                                <div className="container">
                                    <h2>{t('component-5-label-3')}</h2>
                                    <iframe width='100%' height='700'
                                            src={`https://cleveron.zohobookings.eu/portal-embed#/customer/${this.props.contactId || '67150000000017006'}`}
                                            frameBorder='0' allowFullScreen=''></iframe>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

BookDemoModal.propTypes = {
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    contactId: PropTypes.string
};

export default translate(BookDemoModal);