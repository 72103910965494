'use strict';

import React from 'react';
import AOS from 'aos';
// import ReactPlayer from 'react-player'


import $ from "jquery";
import translate from "../translate";
import paths from "../../lib/paths";
import SectionHowMuchImpact from "../section-how-much-impact";
import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import SectionComponent30Percent from "../section-component-30-percent";
import SectionDemo from "../section-demo";
import SectionPricingInformation from "../section-pricing-information";

class AirQuality extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('page-3-label-0', {delay: true})}
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block big-img__img-block--reverse">
                        <img className="img img--big img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-700" alt="" />
                    </div>
                    <div className="big-img__text-block">
                        <h3 data-aos="fade-right" data-aos-delay="650">{t('page-3-label-12')}
                        </h3>
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h4 data-aos="fade-up" data-aos-offset='-10' data-aos-delay="50">{t('page-3-label-2')}</h4>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="150">
                                            <div className="text-section__info">
                                                {t('page-3-label-3')}
                                            </div>
                                        </div>
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="300">
                                            <div className="text-section__info">
                                                {t('page-3-label-4')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('page-3-label-5', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="components" data-aos-id="fixed" data-aos="fixed" data-aos-offset="">
                    <div className="components__del" data-aos="del-fixed" data-aos-anchor="#del-fixed" data-aos-anchor-placement="top-bottom">
                        <div className="components__item active">
                            <div className="components__info-block">
                                <div className="text-block">
                                    <div className="container">
                                        <h3>
                                            CLEVER
                                            <br />
                                            Sense.
                                        </h3>
                                        <div className="text-block__info">
                                            {t('page-3-label-6')}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('page-3-label-7')}
                                            </li>
                                            <li>
                                                {t('page-3-label-8')}
                                            </li>
                                            <li>
                                                {t('page-3-label-9')}
                                            </li>

                                        </ul>
                                        <a href={paths.links.senseDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('page-3-label-24')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="components__img-block">
                                <img className="components__img" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-2-2x_DGQmIegQJ.jpg?tr=w-800"
                                     loading={'lazy'} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <SectionDemo/>
                <SectionPricingInformation />
                <div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('page-3-label-10')}
                    </h3>
                </div>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={phone} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-3-label-11')}</h4>
                                    <div className="text-section__info">
                                        {t('page-3-label-12')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={graphic} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-3-label-13')}</h4>
                                    <div className="text-section__info">
                                        {t('page-3-label-14')}
                                    </div>
                                </div>
                            </div>

                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={wind} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-3-label-15')}</h4>
                                    <div className="text-section__info">
                                        {t('page-3-label-16')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={temperature} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-3-label-17')}</h4>
                                    <div className="text-section__info">
                                        {t('page-3-label-18')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={airQuality} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('page-3-label-19')}</h4>
                                    <div className="text-section__info">
                                        {t('page-3-label-23')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="video-section">
                    <div className="video-section__row">
                        <div className="video-section__text">
                            <h5>CLEVER Sense</h5>
                            <div className="video-section__info">
                                {t('page-3-label-20')}
                            </div>
                            <a onClick={() => this.props.history.push('/news/wie-45-clever-sense-in-der-wessenberg-schule-konstanz-die-raumluftqualitat-auch-nach-der-pandemie-im')} className="btn">{t('page-3-label-22')}</a>
                        </div>
                        <div className="video-section__video">
                            <div className="video">
                                <video controls style={{
                                    width: '100%',
                                    maxHeight: 700
                                }}
                                       poster={'https://ik.imagekit.io/sh/cleveron_ch/Sense_Info_Blog_Uu84G5WyUN.jpg?tr=w-1400'}
                                >
                                    <source
                                        src={'https://cleveron-assets.s3.amazonaws.com/media/POST_ITW_SWEN.mp4'}
                                        type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionComponent30Percent {...this.props}/>
            </>
        )
    }
}

export default translate(AirQuality);