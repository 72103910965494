'use strict';

import React from 'react';
import AOS from "aos";

import translate from "../translate";
import ContactCTA from "../contactCTA";

import logo from '../../assets/logo.svg';
import swisscom from '../../assets/partnership/swisscom.png';
import supsi from '../../assets/partnership/suspi.png';
import interreg from '../../assets/partnership/interreg.png';
import PartnershipForm from '../parnership-form';

class Partnership extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });
    }

    render() {
        const {t} = this.props;
        return (
            <main className="main">
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit" style={{height: 600}}    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 600}} loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-700" alt="" />
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="logo sidebar-section__logo">
                                    <img src={logo} loading={'lazy'} alt="" className="logo__img" />
                                </div>
                                <img className="sidebar-section__client-logo" loading={'lazy'} src={swisscom} alt=""/>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>
                                            {t('10 mio kg CO2<br/> einsparen - jetzt!')}
                                        </h3>
                                        <p>
                                            {t('Mit unserer Kampagne ist es unser Ziel, Gebäude in der Schweiz und Europaweit auszurüsten und damit CO2 einzusparen. Wir sind aktiv dabei, den CO2 Ausstoss zu verringern')}
                                        </p>
                                        <a href="https://swisscom.cleveron.ch/" target={'_blank'} className="btn">{t('Erfahren Sie mehr')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PartnershipForm/>
                <section className="big-img" style={{marginTop: 60}}>
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"    style={{height: 800}}     loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/banner-hp_UD3hiKAvo.jpg" alt="" />
                        <img className="img img--medium img-object-contain" style={{height: 800}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/banner-hp_UD3hiKAvo.jpg" alt="" />
                        <img className="img img--small img-object-contain"                        loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/banner-hp_UD3hiKAvo.jpg?tr=w-700" alt="" />
                    </div>
                </section>
                <section className="sidebar-section" style={{paddingTop: 0}}>
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="logo sidebar-section__logo">
                                    <img src={logo} loading={'lazy'} alt="" className="logo__img" />
                                </div>
                                <img className="sidebar-section__client-logo" src="https://www.ygnis.ch/bundles/gaygnis/images/logo.png"
                                     alt=""/>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>Ygnis AG</h3>
                                        <p>
                                            {t('Ab September 2021 kooperiert CLEVERON mit Ygnis, dem Schweizer Marktführer und Experten für die Herstellung von Heizsystemen. Das 1943 gegründete Unternehmen Ygnis gilt als weltweit bester Hersteller von Heizungsanlagen und verfügt über umfangreiche Erfahrungen im Bereich der Heizungstechnik und -technologie. Die Partnerschaft zwischen CLEVERON und Ygnis steht für die Verschmelzung von innovativer und intelligenter Technologie mit langjähriger Erfahrung und industriellem Know-how mit dem Ziel, noch effizientere Lösungen für unsere Kunden zu schaffen.')}
                                        </p>
                                        <a href="https://www.ygnis.ch/" target={'_blank'} className="btn">{t('Erfahren Sie mehr')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"    style={{height: 600}} alt="" loading={'lazy'} src="https://ik.imagekit.io/sh/shutterstock_1746069485_8vkem__4v.jpg?tr=w-1600" />
                        <img className="img img--medium img-object-fit" style={{height: 600}} alt="" loading={'lazy'} src="https://ik.imagekit.io/sh/shutterstock_1746069485_8vkem__4v.jpg?tr=w-1400" />
                        <img className="img img--small img-object-contain"                    alt="" loading={'lazy'} src="https://ik.imagekit.io/sh/shutterstock_1746069485_8vkem__4v.jpg?tr=w-700" />
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="logo sidebar-section__logo">
                                    <img src={logo} loading={'lazy'} alt="" className="logo__img" />
                                </div>
                                <img className="sidebar-section__client-logo" src={supsi}
                                     loading={'lazy'} alt="" />
                                <img className="sidebar-section__client-logo" src={interreg}
                                     loading={'lazy'} alt="" />
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>
                                            {t('SUPSI und CLEVERON:<br/> das QAES-Projekt')}
                                        </h3>
                                        <p>
                                            {t(`Zu unseren Projekten gehört QAES, was abkürzend für «Luftqualität in Schulgebäuden» steht. Ein Entwicklungsprojekt, das sich mit dem Standard zur Verbesserung der Luft- und Umweltqualität in Schulen beschäftigt. Dies ist eine Zusammenarbeit zwischen Italien und der Schweiz und wir arbeiten mit ihnen zusammen, um schnelle und konkrete Lösungen für dieses Problem zu finden. Die grenzüberschreitende Zusammenarbeit in diesem Projekt trägt zur Analyse und zum Vergleich verschiedener Gegebenheiten bei, nicht nur in Bezug auf das Wissen, sondern auch in wirtschaftlicher Hinsicht. Um mehr zu erfahren, besuchen Sie uns auf der offiziellen QAES Website`)}:
                                        </p>
                                        <a href={'https://www.qaes.it/de/'} target={'_blank'} className="btn">{t('Erfahren Sie mehr')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactCTA/>
            </main>
        );
    }
}

export default translate(Partnership);

