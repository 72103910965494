const paths = {
    home: '/',
    system: '/heating',
    air: '/air',
    agb: '/agb',
    dataProtection: '/data-protection',
    impressum: '/impressum',
    error: '/error/:code/:message',
    about: '/about',
    heating: '/heating',
    products: '/products',
    aboutProject: '/projects',
    testimonials: '/testimonials',
    caseStudy: '/case-study',
    media: '/media',
    mediaTAg: '/media/:tag',
    news: '/news/:slug',
    video: '/video',
    calculator: '/calculator',
    demoRequest: '/demo-request',
    contact: '/contact',
    openCaseStudy: '/case-study/:id',
    partnership: '/partnership',
    _old: {
        whyHow: '/what-how',
        team: '/team',
        pricing: '/pricing',
        references: '/references',
        campaign: '/campaign',
        blog: '/news',
        blogSinglePost: '/blog/:slug',
        blogTag: '/tag/:tag',
        blogCategory: '/category/:category'
    },
    links: {
        pricingList: 'https://info.cleveron.ch/pricing-list',
        thermoDatasheet: 'https://info.cleveron.ch/thermo-datasheet',
        senseDatasheet: 'https://info.cleveron.ch/sense-datashee',
        applicationAreas: 'https://info.cleveron.ch/examples',
        bookDemoCall: 'https://info.cleveron.ch/book-demo-call'

    }
};

module.exports = paths;