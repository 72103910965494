'use strict';

import React from 'react';

import Language from '../language';
import translate from '../translate';
import paths from '../../lib/paths'


class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    // @TODO update social links href
    render() {
        const {t} = this.props;
        return (
            <footer className='footer'>
                <div className='footer__main'>
                    <div className='wrapper'>
                        <div className='footer__row'>
                            <div>
                                <img src='https://ik.imagekit.io/sh/cleveron_ch/logo_JNMCm01DM.svg?updatedAt=1634907854940' alt='' className='footer__logo'/>
                                <h5 className='footer__logo-title'>{t('component-2-label-0')}</h5>
                                <div className='footer__address-container'>
                                    <span className={'footer__address-container__address'}> {t('component-2-label-4', false)}</span>
                                </div>
                                <div className='footer__address-container'>
                                    <span className={'footer__address-container__address'}>
                                        {'Cleveron Europe GmbH'}<br/>
                                        {'Auf der Höhe 15'}<br/>
                                        {'78576 Emmingen-Liptingen, Deutschland'}<br/>
                                    </span>
                                </div>
                                <div className='social'>
                                    <div className='social__box'>
                                        <a href='https://www.linkedin.com/company/cleveron-ag/' target="_blank" className='social__link'>

                                            <svg className="social__img" xmlns="http://www.w3.org/2000/svg"
                                                 width="20.965" height="20.039"
                                                 viewBox="0 0 20.965 20.039">
                                                <g id="linkedin" transform="translate(0 0.668)">
                                                    <path className="fill"
                                                          d="M2.542-.668A2.342,2.342,0,1,0,2.483,4h.029a2.343,2.343,0,1,0,.03-4.672Zm0,0"/>
                                                    <path className="fill" d="M8.109,198.313H12.6v13.521H8.109Zm0,0"
                                                          transform="translate(-7.844 -192.462)"/>
                                                    <path className="fill"
                                                          d="M229.759,188.625a5.63,5.63,0,0,0-4.05,2.278v-1.961h-4.494v13.521h4.494v-7.551a3.08,3.08,0,0,1,.148-1.1,2.46,2.46,0,0,1,2.306-1.643c1.626,0,2.277,1.24,2.277,3.058v7.233h4.494v-7.753C234.933,190.558,232.716,188.625,229.759,188.625Zm0,0"
                                                          transform="translate(-213.968 -183.092)"/>
                                                </g>
                                            </svg>

                                        </a>
                                        <a href='https://www.instagram.com/cleveronag/' target="_blank" className='social__link' >
                                            <svg className="social__img" xmlns="http://www.w3.org/2000/svg"
                                                 width="19.475" height="19.478"
                                                 viewBox="0 0 19.475 19.478">
                                                <g id="instagram" transform="translate(-0.449)">
                                                    <path className="fill"
                                                          d="M19.874,5.727a7.117,7.117,0,0,0-.453-2.363,4.99,4.99,0,0,0-2.85-2.85A7.136,7.136,0,0,0,14.209.061C13.166.011,12.835,0,10.19,0S7.215.011,6.176.057A7.119,7.119,0,0,0,3.813.51,4.753,4.753,0,0,0,2.085,1.636,4.8,4.8,0,0,0,.963,3.36,7.137,7.137,0,0,0,.51,5.723C.461,6.766.449,7.1.449,9.741s.011,2.976.057,4.014a7.117,7.117,0,0,0,.453,2.363,4.989,4.989,0,0,0,2.85,2.85,7.137,7.137,0,0,0,2.363.453c1.039.046,1.37.057,4.014.057s2.976-.011,4.014-.057a7.116,7.116,0,0,0,2.363-.453,4.982,4.982,0,0,0,2.85-2.85,7.141,7.141,0,0,0,.453-2.363c.046-1.039.057-1.37.057-4.014S19.92,6.766,19.874,5.727ZM18.12,13.679a5.339,5.339,0,0,1-.335,1.807,3.231,3.231,0,0,1-1.849,1.849,5.358,5.358,0,0,1-1.807.335c-1.027.046-1.336.057-3.934.057s-2.911-.011-3.935-.057a5.336,5.336,0,0,1-1.807-.335,3,3,0,0,1-1.119-.727,3.028,3.028,0,0,1-.727-1.119,5.359,5.359,0,0,1-.335-1.807c-.046-1.027-.057-1.336-.057-3.935s.011-2.911.057-3.934a5.336,5.336,0,0,1,.335-1.807,2.961,2.961,0,0,1,.731-1.119,3.024,3.024,0,0,1,1.119-.727,5.361,5.361,0,0,1,1.807-.335C7.291,1.781,7.6,1.769,10.2,1.769s2.911.011,3.935.057a5.339,5.339,0,0,1,1.807.335,3,3,0,0,1,1.119.727,3.028,3.028,0,0,1,.727,1.119,5.361,5.361,0,0,1,.335,1.807c.046,1.027.057,1.336.057,3.934S18.166,12.652,18.12,13.679Zm0,0"/>
                                                    <path className="fill"
                                                          d="M129.953,124.5a5,5,0,1,0,5,5A5,5,0,0,0,129.953,124.5Zm0,8.25A3.246,3.246,0,1,1,133.2,129.5,3.246,3.246,0,0,1,129.953,132.75Zm0,0"
                                                          transform="translate(-119.763 -119.763)"/>
                                                    <path className="fill"
                                                          d="M364.786,89.77a1.168,1.168,0,1,1-1.168-1.168A1.168,1.168,0,0,1,364.786,89.77Zm0,0"
                                                          transform="translate(-348.226 -85.23)"/>
                                                </g>
                                            </svg>

                                        </a>
                                        <a href='https://www.facebook.com/CleveronAg/?ref=br_rs' target="_blank" className='social__link'>
                                            <svg className="social__img" id="facebook"
                                                 width="12.323"
                                                 height="23.277"
                                                 viewBox="0 0 12.323 23.277">
                                                <path className="fill" id="Facebook-2" data-name="Facebook"
                                                      d="M8,23.277V12.66h3.632l.545-4.139H8V5.879c0-1.2.338-2.014,2.091-2.014h2.233V.162A30.858,30.858,0,0,0,9.069,0C5.848,0,3.643,1.928,3.643,5.469V8.521H0V12.66H3.643V23.277Z"/>
                                            </svg>
                                        </a>
                                        <a href='https://twitter.com/@CleveronAg' target="_blank" className='social__link'>
                                            <svg className="social__img" id="twitter"
                                                 width="24.732"
                                                 height="20.09"
                                                 viewBox="0 0 24.732 20.09">
                                                <path className="fill" id="Shape-2" data-name="Shape"
                                                      d="M24.732,2.377a10.132,10.132,0,0,1-2.913.8A5.1,5.1,0,0,0,24.049.371,10.228,10.228,0,0,1,20.825,1.6,5.078,5.078,0,0,0,12.18,6.228,14.4,14.4,0,0,1,1.723.927,5.079,5.079,0,0,0,3.293,7.7,5.074,5.074,0,0,1,1,7.063v.063A5.079,5.079,0,0,0,5.064,12.1a5.139,5.139,0,0,1-1.337.178,4.87,4.87,0,0,1-.955-.094,5.076,5.076,0,0,0,4.738,3.524,10.179,10.179,0,0,1-6.3,2.168A10.783,10.783,0,0,1,0,17.807,14.341,14.341,0,0,0,7.777,20.09,14.335,14.335,0,0,0,22.212,5.655L22.195,5A10.132,10.132,0,0,0,24.732,2.377Z"/>
                                            </svg>
                                        </a>
                                        <a href='https://www.youtube.com/channel/UCT0-SxAVWOpZz0VM91MGXqw' target="_blank" className='social__link'>
                                            <svg className="social__img" aria-hidden="true" focusable="false"
                                                 data-prefix="fab" data-icon="youtube"
                                                 role="img"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path className="fill"
                                                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                                </path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='footer__link-col'>
                                <h3 className='footer__link-header'>{t('component-2-label-10')}</h3>
                                <a className='footer__link' href={paths.impressum}>{t('component-2-label-5')}</a>
                                <a className='footer__link' href={paths.agb}>{t('component-2-label-6')}</a>
                                <a className='footer__link' href={'mailto: jobs@cleveron.ch'}>{t('component-2-label-7')}</a>
                            </div>
                            <div className='footer__link-col'>
                                <h3 className='footer__link-header'>{t('component-2-label-11')}</h3>
                                <a className='footer__link' href={paths.heating}>{t('component-2-label-1')}</a>
                                <a className='footer__link' href={paths.air}>{t('component-2-label-2')}</a>
                                <a className='footer__link' href={'https://docs.cleveron.ch'} target={'_blank'}>{t('component-2-label-9')}</a>
                            </div>
                            <div className='footer__link-col' style={{marginBottom: 20}}>
                                <h3 className='footer__link-header'>{t('component-2-label-12')}</h3>
                                <a className="footer__link"  href={paths.contact}>{t('component-2-label-3')}</a>
                                <a href="mailto:info@cleveron.ch" className="footer__link">info@cleveron.ch</a>
                            </div>
                            <div className='footer__language-social' >
                                {<Language />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer__bottom'>
                    <div className='wrapper'>
                        <div className='footer__bottom-row'>
                            <a href={paths.agb}>Terms & Conditions</a>
                            <span>Cleveron AG © 2024. All rights reserved</span>
                        </div>
                    </div>
                </div>
            </footer>
        );
    };
}

export default translate(Footer);
