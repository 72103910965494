'use strict';

import React from 'react';
import swal from 'sweetalert';
import Parse from 'parse';

import translate from '../translate';

const isValidEmail = require('is-valid-email');

class PartnershipForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                email: '',
                comment: '',
                company: '',
                phone: ''
            },
            errors: {
                name: false,
                email: false,
                phone: false,
                company: false
            }
        }
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        document.getElementById('partnership-form').onSubmit = function () {
            return this.sendForm();
        };
    }

    inputValues(event, field) {
        let value = event.target.value;

        if (field === 'phone') {
            value = value.replace(/\D/g,'');
        }

        this.setState(prev => {
            prev.form[field] = value;

            prev.errors[field] = false;

            return prev;
        });
    }

    async sendForm(e) {
        const {form: {email, company, phone, comment, name}} = this.state;
        const {t} = this.props;

        this.setState({
            form: {...this.state.form},
            errors: {
                name: !name,
                email: !email || !isValidEmail(email),
                company: !company,
                phone: !phone,
                isValidEmail: !isValidEmail(email)
            }
        });

        if (!(name && email && company && phone && isValidEmail(email))) {
            e.preventDefault();
            return
        }

        try {
            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            let content = `${comment}\n\nTel: ${phone}\n`;
            await Parse.Cloud.run('send-email', {
                mail: email,
                name,
                subject: company,
                comment: content,
                token
            });

            this.setState({
                form: {
                    name: '',
                    company: '',
                    phone: '',
                    email: '',
                    comment: ''
                },
                errors: {
                    name: false,
                    email: false,
                    company: false,
                    phone: false
                }
            });

            swal(t('component-9-label-13'), t('component-9-label-12'), 'success');
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }


    render() {
        const {errors, form: {name, email, phone, company, comment}} = this.state;
        const {t} = this.props;

        return (

            <section className="basic-section partnetship-form-section">
                <div className="basic-section__row">
                    <div className="text-block">
                        <div className="container">
                            <div>
                                <h3>
                                    {t('component-9-label-0')}
                                </h3>
                            </div>
                            <div className="basic-section__row">
                                <div className='partnership-form-description'>
                                    {t('component-9-label-1')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="container">
                            <form
                                id='partnership-form'
                                onSubmit={this.sendForm}
                                className="form"
                            >
                                <fieldset>
                                    <label className={`form__label ${errors.name ? 'error' : ''}`} data-label=""
                                           data-error={t('component-9-label-2')}>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form__input"
                                            placeholder={t('component-9-label-3')}
                                            onChange={(e) => this.inputValues(e, 'name')}
                                            value={name}
                                        />
                                    </label>
                                    <label className={`form__label ${errors.company ? 'error' : ''}`} data-label=""
                                           data-error={t('component-9-label-4')}>
                                        <input
                                            name="company"
                                            type="text"
                                            className="form__input"
                                            placeholder={t('component-9-label-5')}
                                            onChange={(e) => this.inputValues(e, 'company')}
                                            value={company}
                                        />
                                    </label>
                                    <label
                                        className={`form__label ${errors.email ? 'error' : ''}`}
                                        data-error={t('component-9-label-6')}>
                                        <input
                                            name="mail"
                                            type="email"
                                            className="form__input"
                                            placeholder={t('component-9-label-7')}
                                            value={email}
                                            onChange={(e) => this.inputValues(e, 'email')}
                                        />
                                    </label>
                                    <label className={`form__label ${errors.phone ? 'error' : ''}`} data-label=""
                                           data-error={t('component-9-label-8')}>
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form__input"
                                            placeholder={t('component-9-label-9')}
                                            value={phone}
                                            onChange={(e) => this.inputValues(e, 'phone')}
                                        />
                                    </label>
                                    <label className="form__label" data-label="">
                                            <textarea
                                                name="comment"
                                                cols="30"
                                                rows="10"
                                                value={comment}
                                                placeholder={t('component-9-label-10')}
                                                onChange={(e) => this.inputValues(e, 'comment')}
                                            />
                                    </label>
                                    <label className="form__submit">
                                        <button type="button"
                                                className='partnetship-form-section-btn'
                                                onClick={this.sendForm}>{t('component-9-label-11')}</button>
                                    </label>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default translate(PartnershipForm);