'use strict'

import React from 'react';
import moment from 'moment';

import i18next from '../i18n'
import translate from "../translate";
import germanyFlag from '../../assets/icon/language/de.svg';
import ukFlag from '../../assets/icon/language/en.svg';
import italyFlag from '../../assets/icon/language/it.svg';
import frenchFlag from '../../assets/icon/language/fr.svg';
import chFlag from '../../assets/icon/language/ch.svg';

let languages = {
    de: {
        label: 'Deutsch',
        icon: chFlag,
        icon2: germanyFlag
    },
    en: {
        label: 'English',
        icon: ukFlag
    },
    fr: {
        label: 'Français',
        icon: frenchFlag
    },
    it: {
        label: 'Italiano',
        icon: italyFlag
    }
}

let defaultLanguage = 'de';

class Language extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showLanguage: null
        }
        this.sortedLanguageKeys = this.sortedLanguageKeys.bind(this);
    }

    changeLanguage(language) {

        moment.locale(language);
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    sortedLanguageKeys() {
        let keys = Object.keys(languages);
        let originalOrder = Object.keys(languages);
        keys.sort((a, b) => {
            if (this.props.i18n.language === a) {
                return -1;
            }
            if (this.props.i18n.language === b) {
                return 1;
            }
            return originalOrder.indexOf(a) - originalOrder.indexOf(b);
        });
        return keys;
    }

    render() {
        return (
            <div className='language'>
                <div className='language__current'>
                    <img src={languages[this.props.i18n.language]?.icon ?? languages[defaultLanguage].icon}
                         alt='' className='language__img' />
                    {
                        languages[this.props.i18n.language].icon2 != null && <img
                            src={languages[this.props.i18n.language]?.icon2 ?? languages[defaultLanguage].icon}
                            alt='' className='language__img' />
                    }
                    <span className='language__label'>
                        {languages[this.props.i18n.language]?.label ?? languages[defaultLanguage].label}
                    </span>
                </div>
                <ul className='language__list'>
                    {this.sortedLanguageKeys(languages).map((language) => (
                        <li key={language} className='language__item' onClick={(e) => {
                            e.preventDefault();
                            this.changeLanguage(language);
                        }}>
                            <a href='#' className='language__link'>
                                <img src={languages[language].icon} alt='' className='language__img'/>
                                {
                                    languages[language].icon2 != null && <img
                                        src={languages[language].icon2} alt='' className='language__img'/>
                                }
                                <span className='language__label'>{languages[language].label}</span>
                            </a>
                        </li>
                    ))}

                </ul>
            </div>
        )
    }
}

export default translate(Language);