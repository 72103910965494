'use strict';

import React from 'react';
import AOS from "aos";
import axios from 'axios';
import Parse from 'parse';

import translate from "../translate";
import db from "../../lib/structure";
import paths from "../../lib/paths";
import SectionHowMuchImpact from "../section-how-much-impact";
import SectionDiscoverApplication from "../section-discover-application";

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            caseStudies: []
        };

        this.loadCaseStudies = this.loadCaseStudies.bind(this);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        /*const mapLoader = new google.maps.plugins.loader.Loader({
            apiKey: 'AIzaSyC2Hw6Y8OoSnHbcur5CGtVFZtVavZqlz0w',
            version: "weekly",
        })
        mapLoader.load().then(() => {
            const mapElement = document.getElementById('map-section');
            const options = {
                zoom: 5,
                center: {lat: 53.41058, lng: -2.97794}
            };

            const googleMap = new google.maps.Map(mapElement, options);
            const marker = new google.maps.Marker({
                position: {lat: 53.41058, lng: -2.97794},
                map: googleMap
            });
            const infoWindow = new google.maps.InfoWindow({
                content: '<h4>We here!</h4>'
            });
            marker.addListener('click', function () {
                infoWindow.open(googleMap, marker);
            });
        })*/

        this.loadCaseStudies();
    }

    handleSelector () {
        const selector = document.getElementById('selector')

        if (selector.classList.contains('active')) {
            selector.classList.remove('active')
        } else {
            selector.classList.add('active')
        }
    }

    async loadCaseStudies(){
        let response = await Parse.Cloud.run(
            'crm-case-studies',
            Object.fromEntries(new URLSearchParams(window.location.search))
        );

        this.setState({caseStudies: response.caseStudies});
    }

    render() {
        const {t} = this.props;
        return (
            <main className="main">
                <section className="sidebar-section" style={{paddingBottom: 0}}>
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h3>
                                    {t('Case Studies')}
                                </h3>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <p>
                                            {t(`Inspiration und Referenz. Wählen Sie einen unserer exklusiven Berichte über Kunden, die bereits CLEVERON Technologien implementiert haben`)}
                                        </p>
                                        <div className="faq">
                                            <ul className="faq__list">
                                                <li onClick={this.handleSelector} id='selector' className="faq__item faq__item--column">
                                                    <h4 className="faq__quastion">
                                                        {t('Unsere Fallstudien')}
                                                    </h4>
                                                    <div className="faq__answer">
                                                        {
                                                            this.state.caseStudies.map((caseStudy, i) => {
                                                                return <a key={i} className="h4 case__link" onClick={() => this.props.history.push(`/case-study/${caseStudy.id}`)} style={{marginTop: 10}}>
                                                                    {caseStudy[db.ZohoBuilding.NAME]}
                                                                </a>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="faq__open-btn">
                                                        <span className="faq__btn-line" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="map-section zoho-map">
                    <iframe frameBorder={0} width="100%" height="100%"
                            src="https://analytics.zoho.eu/open-view/43018000002305002/5ab1b4eb406acc12a95c96062445dbcb"></iframe>
                </section>
                <SectionDiscoverApplication/>
                <SectionHowMuchImpact/>
            </main>
        );
    }
}

export default translate(CaseStudy);

